// @ts-nocheck
export const editorReady = async (sdk, appToken) => {
  const QUIZ_APP_DEF_ID = '891783ee-0cfe-44c8-8c52-63920dbeb668';
  const TPA_PAGE_ID_QUIZZES = 'Quizzes';
  const TPA_PAGE_ID_QUIZ = 'Quiz';
  const [{ applicationId }, pages] = await Promise.all([
    sdk.tpa.app.getDataByAppDefId(appToken, QUIZ_APP_DEF_ID),
    sdk.pages.data.getAll(),
  ]);

  let quizPageRef;

  if (pages) {
    pages
      .filter(
        (page) =>
          page.tpaApplicationId === applicationId &&
          [TPA_PAGE_ID_QUIZZES, TPA_PAGE_ID_QUIZ].includes(page.tpaPageId),
      )
      .forEach(async (page) => {
        const data = { managingAppDefId: QUIZ_APP_DEF_ID };

        if (page.tpaPageId === TPA_PAGE_ID_QUIZ) {
          quizPageRef = { id: page.id, type: page.type };
          data.title = 'Quiz';
        }

        await sdk.pages.data.update(appToken, {
          pageRef: { id: page.id, type: page.type },
          data,
        });
      });
  }

  if (quizPageRef) {
    await sdk.document.pages.setState(appToken, {
      state: {
        quizPage: [quizPageRef],
      },
    });
  }

  return Promise.resolve();
};

export const handleAction = () => Promise.resolve();
export const onEvent = () => Promise.resolve();
export const getAppManifest = () => {
  return {
    pages: {
      pageActions: {
        default: [],
        quizPage: [],
      },
      pageSettings: {
        default: [
          {
            title: 'Page Info',
            type: 'page_info',
          },
          {
            title: 'Layout',
            type: 'layout',
          },
          {
            title: 'Permissions',
            type: 'permissions',
          },
          {
            title: 'SEO Basics',
            type: 'seo',
          },
        ],
        quizPage: [
          {
            title: 'Page Info',
            type: 'page_info',
          },
          {
            title: 'Layout',
            type: 'layout',
          },
          {
            title: 'Permissions',
            type: 'permissions',
          },
          {
            title: 'SEO Basics',
            type: 'seo',
          },
        ],
      },
      applicationSettings: {
        default: {
          displayName: 'Quiz Pages',
        },
      },
      applicationActions: {
        default: {
          defaultValues: [],
        },
      },
      pageDescriptors: {
        default: {
          icon: 'blogPageType',
        },
      },
    },
  };
};
export const getControllerPresets = () => Promise.resolve([]);
